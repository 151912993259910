.member-tooltip:before {
  position: absolute;
  width: 10px;
  height: 10px;
  background: inherit;
  top: -5px;
  left: 42%;
}

.member-tooltip:before {
  content: '';
  visibility: visible;
  transform: rotate(45deg);
}

.supplier-avatar:hover > .member-tooltip {
  display: block;
}
