.order-tooltip:before {
  position: absolute;
  width: 10px;
  height: 10px;
  background: inherit;
  top: -5px;
  left: 42%;
}

.order-tooltip:before {
  content: '';
  visibility: visible;
  transform: rotate(45deg);
}

.order-cell:hover > .order-tooltip {
  display: block;
}
