.reverse-italic {
  -moz-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(10deg, 0deg);
  -webkit-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(10deg, 0deg);
  -o-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(10deg, 0deg);
  -ms-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(10deg, 0deg);
  transform: scale(1) rotate(0deg) translate(0px, 0px) skew(10deg, 0deg);
}

.overlay {
  transform: translateY(100%);
}

.avatar-input:hover > .overlay {
  transform: translateY(0);
}
