.otp-input {
  width: 100% !important;
  border: 1px solid #80808050;
  padding: 10px;
  margin: 12px 8px;
}

.otp-input:focus {
  border: 1px solid #0162e1;
}
